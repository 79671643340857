import React from 'react';
import ReactPlayer from 'react-player';
const Spotify = () => {
  return (
    <section className="ld--wrap-spotify">
      <div className="row">
        <div className="col-12 col-lg-12 d-flex flex-column mb-5">
          <div className="ld--title">Video</div>
          <div className="ld--card w-100 ">
            <div className="player-wrapper">
              <ReactPlayer
                url={`${process.env.REACT_APP_URL_IMAGES}la-ruta-de-los-templos-paganos-ticketmundo.mp4`}
                className="react-player"
                playing
                width="100%"
                height="100%"
                controls={true}
                light={`${process.env.REACT_APP_URL_IMAGES}cover-templo-paganos-ticketmundo.jpg`}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Spotify;
